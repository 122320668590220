<template>
  <app-container title="实名认证" :background="tool.getThemeList(theme)">
    <div class="bind_box">
      <div class="bind_content">
        <!-- 卡号 -->
        <div class="card_num">
          <span class="desc">卡号:</span>
          <div @click="focusCardInput" style="
          width: calc(100% - 158px);
          border: 1px solid transparent;
          height: 20px;
          position: absolute;
          right: 40px;">{{maskMiddleCharacters(card)}}</div>
          <input
            ref="cardInput"
            type="text"
            style="border: none;color: transparent;caret-color: rgba(0,0,0,.4);"
            placeholder="请填写卡号"
            v-model="card"
          />
        </div>
        <!-- <div class="card_verifyCode">
          <span class="card_verifyCode_label">图形验证:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请填写图形验证"
            v-model="verifyCode"
          />
          <span class="verifyCode" @click="setImgVerifyCode()">{{initVerifyCode}}</span>
        </div> -->
        <div class="card_mobile_underline"></div>
        <!-- 手机验证码组件 -->
        <verification-code @emitNum="getNum"></verification-code>
        <div class="card_code_underline"></div>
        <!-- 验证码 -->
        <div class="code_num">
          <span class="desc">验证码:</span>
          <input
            type="text"
            style="border: none"
            placeholder="请输入验证码"
            v-model="code"
            maxlength="6"
          />
        </div>
        <div class="card_num_underline"></div>
        <div class="card_code_num_underline"></div>
      </div>
    </div>
    <!-- 提交按钮 -->
    <div class="submit_button" @click="submit">
      提交绑定
    </div>
    <!-- 温馨提示 -->
    <div class="explain">
      <div class="explain_desc">温馨提示:</div>
      <div class="explain_text">1、请输入有效的手机号码</div>
      <div class="explain_text">2、如果手机号码停用将会导致绑定失败</div>
      <div class="explain_text">3、图形校验区分大小写</div>
    </div>
  </app-container>
</template>

<script>
import { toRefs, reactive, onMounted, getCurrentInstance ,nextTick } from "vue";
import { setStore,getStore,removeStore } from "@/utils/store";
import { useRoute } from "vue-router";
import { bindMobile, pageNoShowIccidFetchRealUrl } from "_API_/api.services";
import { isvalidatemobile } from "@/utils/validate";
export default {
  setup() {
    const state = reactive({
      title: "", //头部标题
      theme: "", //主题名称
      card: "", //卡号
      iccid: "", //iccid卡号
      iccids: [], //用户绑定的卡片列表
      cardId: "", //卡ID
      code: "", //验证码
      // initVerifyCode: "", //图形验证码
      // verifyCode: "", //图形验证码
      mobile: "", //手机号码
      // nickname: "", //卡片昵称
      testType: 1,
      pageType: "", //绑定类型
      // realNameUrl: "", //实名地址
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      focusCardInput () {
        this.$refs.cardInput.focus();
      },
      maskMiddleCharacters(str) {
        if (str.length < 19) {
          return str;
        }
        
        const lastIndex = str.length - 1;
        const replacedStr = str.slice(0, lastIndex - 5) + '******';
        
        return replacedStr;
      },
      // 设置图形验证码
      // setImgVerifyCode () {
      //   var code = '';
      //   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      //   for (var i = 0; i < 4; i++) {
      //     code += characters.charAt(Math.floor(Math.random() * characters.length));
      //   }
      //   state.initVerifyCode = code;
      // },
      //获取手机号码
      getNum(msg) {
        state.mobile = msg.mobile;
      },
      //卡片绑定信息
      submit() {
        // if (state.initVerifyCode != state.verifyCode) {
        //   return tool.toast(
        //       {
        //         msg: "图形验证失败，请重试",
        //         duration: 1500
        //       },
        //       () => {}
        //     );
        // }
        //提交绑定手机号码
        ctx.bindCard();
      },
      //绑定卡片
      bindCard() {
        //手机验证码组件没传手机号码过来时，再次验证手机号
        let result_phone = isvalidatemobile(state.mobile);
        if (result_phone[0]) {
          tool.alert({
            title: "提示",
            msg: "请输入正确的手机号并获取验证码"
          });
          return;
        }
        //验证码校验
        if (!Boolean(state.code)) {
          tool.toast(
            {
              msg: "请输入验证码" + state.code,
              duration: 1500
            },
            () => {}
          );
          return;
        }
        //调用绑定手机号码接口
        bindMobile({
          card: state.iccid || state.card,
          cardId: state.cardId,
          code: state.code,
          mobile: state.mobile,
          // nickname: state.nickname,
          testType: 1,
          IDCard: "",
          realName: "",
          cardPic1: "",
          cardPic2: "",
          exchangeCode: ""
        }).then(({ data: res }) => {
          if (res.code == 0) {
            tool.toast(
              {
                msg: "绑定成功",
                duration: 1500
              },
              () => {
                //存储绑定标识到sessionStorage
                setStore({
                  name: "userBind",
                  content: {
                    isShow: "2",
                    // nickname: state.nickname,
                    mobile: state.mobile
                  },
                  type: "session"
                });
                //存储用户信息
                store.dispatch("SetUserInfo", {
                  iccid: state.card
                });
                //保存loginAccessToken
                store.dispatch("SetLoginToken", state.card);
                //返回个人中心页面
                // router.push({
                //   name: "personal"
                // });
                // if (state.realNameUrl) {
                //   location.href = state.realNameUrl
                // }
                // 查询实名状态 - 跳转
                pageNoShowIccidFetchRealUrl({
                  card: state.card, 
                  backurl: window.location.origin + '/query?iccid=' + state.card 
                }).then(({ data }) => {
                    if (data.code == 0 && data.data.realNameUrl) {
                      location.href = data.data.realNameUrl
                    }
                  })
              }
            );
            return;
          }
          if (res.code == 222) {
            tool.toast(
              {
                msg: "身份认证失败，请上传身份证照提交绑定",
                duration: 1500
              },
              () => {}
            );
            return;
          }
          tool.toast(
            {
              msg: res.msg, 
              duration: 1500
            },
            () => {}
          );
        });
      },
      // //检测路由参数
      // judgeRoute() {
      //   state.card = route.query.card;
      // }
    };
    onMounted(() => {
      // methods.judgeRoute();
      // methods?.setImgVerifyCode()
      //获取主题名称
      state.theme = store.state.common.theme;
      //获取用户信息
      const info = store.state.user.userInfo;
      state.title = "实名认证";
			Object.assign(state, {
          cardId: info.cardId || "", //获取卡id
          card: info.virtualId || info.iccid || route.query.card ||  "", //获取卡号
          iccids: info.iccids //用户绑定的卡片列表
        });
    });
    return { ...toRefs(state), ...methods, tool };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.bind_box {
  width: 690px;
  background: #ffffff;
  border-radius: 20px;
  margin: 40px auto;
  padding-top: 20px;
  .bind_content {
    width: 630px;
    height: 328px;
    margin: 0 auto;
    .card_num,
    .card_verifyCode,
    .code_num,
    .real_name {
      height: 88px;
      background: #ffffff;
      margin: 0 auto;
      display: flex;
      align-items: center;
      
      .card_verifyCode_label {
        width: 144px;
        font-size: 30px;
        font-weight: 400;
        color: #333333
      }
      .desc {
        width: 144px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #333333;
      }
      .real_name_input {
        width: 430px;
        margin-left: 40px;
      }
    }
    .card_num,
    .card_verifyCode,
    .code_num {
      width: 600px;
      justify-content: space-between;
      input {
        width: 430px;
        margin-right: 60px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .code_num {
      width: 600px;
      justify-content: space-between;
      input {
        width: 430px;
        margin-right: 50px;
      }
      .desc {
        padding-left: 4px;
      }
    }
    .card_verifyCode {
      position: relative;
      input {
        text-indent: 1em;
      }
      .verifyCode{
        width: 100px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        text-align: center;
        position: absolute;
        top: 20px;
        right: 10px;
        color: #FFF;
        background: rgba(#aaaaaa,1);
        padding: 2px 8px;
        border-radius: 6px;
      }
    }
    .real_name {
      width: 630px;
      margin-left: 20px;
      input {
        width: 430px;
        margin-left: 100px;
        font: {
          size: 30px;
          weight: 400;
        }
        color: #aaaaaa;
      }
    }
    .card_mobile_underline,
    .card_num_underline,
    .card_code_underline,
    .card_code_num_underline {
      height: 1px;
      background: #f2f2f2;
    }
    .card_mobile_underline {
      width: 490px;
      margin: 0 130px;
    }
    .card_num_underline {
      width: 480px;
      margin: 0 140px;
    }
    .card_code_underline {
      width: 426px;
      margin: 0 190px;
    }
    .card_code_num_underline {
      width: 500px;
      margin: 0 126px;
    }
  }
}

.explain {
  width: 690px;
  height: 188px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .explain_desc {
    font: {
      size: 30px;
      weight: 400;
    }
    color: #333333;
  }
  .explain_text {
    width: 690px;
    font: {
      size: 22px;
      weight: 400;
    }
    color: #777777;
  }
}
.submit_button {
  width: 690px;
  height: 84px;
  color: #ffffff;
  @include background_color("background_color1");
  border-radius: 20px;
  margin: 20px auto 20px;
  text-align: center;
  line-height: 84px;
}
</style>
